import Vue from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import store from "./common/store/index.js";

import "ant-design-vue/dist/antd.less";

import "./assets/css/font.css";
import "./assets/css/common.css";

Vue.use(Antd);

Vue.config.productionTip = false;

import router from "./common/router/router";
import * as http from "./common/request/request.js";
Vue.prototype.$http = http;
import { common } from "./common/mixin/common.js";
Vue.mixin(common);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  EN: require("./lang/en.json"), // 英文语言包
  CN: require("./lang/zh.json"), // 中文语言包
};
export function getLanguage() {
  return localStorage.getItem("LocalLang") || "CN";
}
const i18n = new VueI18n({
  locale: getLanguage(), // 默认语言为中文
  fallbackLocale: "CN", // 当前语言不存在时的回退语言
  messages,
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
