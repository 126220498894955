//默认语言
const langKey = "LocalLang";
export default {
  namespaced: true,
  state: {
    //默认中文
    lang: "",
    // 语言种类
    languageArr: [
      {
        name: "中文",
        value: "CN",
      },
      {
        name: "English",
        value: "EN",
      },
    ],
  },
  actions: {
    // 切换语言
    Act_setLanguage({ commit }, value = "") {
      commit("setLanguage", value);
    },
    // 初始化语言
    Act_initLanguage({ commit }) {
      commit("initLanguage");
    },
  },
  mutations: {
    //初始化默认语言
    initLanguage(state) {
      var lang = localStorage.getItem(langKey);
      if (!lang) {
        state.lang = "CN";
      } else {
        state.lang = localStorage.getItem(langKey);
      }
    },
    //切换语言
    setLanguage(state, lang) {
      state.lang = lang;
      localStorage.setItem(langKey, lang);
    },
  },
};
